// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-polityka-cookies-js": () => import("./../../../src/pages/polityka-cookies.js" /* webpackChunkName: "component---src-pages-polityka-cookies-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-program-partnerski-hidden-js": () => import("./../../../src/pages/program-partnerski-hidden.js" /* webpackChunkName: "component---src-pages-program-partnerski-hidden-js" */),
  "component---src-pages-program-partnerski-js": () => import("./../../../src/pages/program-partnerski.js" /* webpackChunkName: "component---src-pages-program-partnerski-js" */),
  "component---src-pages-referencje-js": () => import("./../../../src/pages/referencje.js" /* webpackChunkName: "component---src-pages-referencje-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-uslugi-js": () => import("./../../../src/pages/uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-reference-js": () => import("./../../../src/templates/reference.js" /* webpackChunkName: "component---src-templates-reference-js" */)
}

